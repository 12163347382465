import { KYCConfig, KYCDocStatus, KYCStatus } from "../types";

export const sanitizePhoneNumber = (phoneNumber: string): string => {
  // Remove all non-digit characters
  const sanitizedNumber = phoneNumber.replace(/\D/g, "");

  // Check if the sanitized number starts with "91", and if so, remove it
  if (sanitizedNumber.startsWith("91") && sanitizedNumber.length === 12) {
    return sanitizedNumber.substring(2);
  }

  // Check if the sanitized number starts with "+91", and if so, remove it
  if (sanitizedNumber.startsWith("+91")) {
    return sanitizedNumber.substring(3);
  }

  // Check if the sanitized number has exactly 10 digits
  if (sanitizedNumber.length === 10) {
    return sanitizedNumber;
  }

  // If the number doesn't match any expected format, return an empty string
  return "";
};

export const validatePanNumber = (panNumber: string): boolean => {
  const pattern: RegExp = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  return pattern.test(panNumber);
};

export const validateDrivingLicenseNumber = (dl: string): boolean => {
  const pattern: RegExp = /^[A-Za-z]{2}\d{13}$/;
  return pattern.test(dl);
};

export const getKYCDocStatusTitle = (status: KYCDocStatus): string => {
  switch (status) {
    case KYCDocStatus.CREATED:
      return "Created 🚀";

    case KYCDocStatus.PROCESSING:
    case KYCDocStatus.IN_REVIEW:
      return "In Review 🔍";

    case KYCDocStatus.SUCCESSFUL:
      return "Successful ✅";

    case KYCDocStatus.REJECTED:
      return "Rejected ❌";

    case KYCDocStatus.CANCELLED:
      return "Cancelled 🚫";

    case KYCDocStatus.NO_ACTION_REQUIRED:
      return "No Action Required 🤷‍♂️";

    default:
      return "";
  }
};

export const getKYCStatusTitle = (status: KYCStatus): string => {
  switch (status) {
    case KYCStatus.DRAFT:
      return "Draft 📝";

    case KYCStatus.CREATED:
      return "Created  📝";

    case KYCStatus.IN_REVIEW:
      return "In Review 🔍";

    case KYCStatus.SUCCESSFUL:
      return "Successful ✅";

    case KYCStatus.REJECTED:
      return "Rejected ❌";

    case KYCStatus.CANCELLED:
      return "Cancelled 🚫";

    default:
      return "";
  }
};

export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const constructKYCConfigKeyFromConfig = (config: KYCConfig): string => {
  let result = "";

  Object.keys(config.documents).map((key: string) => {
    if (result === "") {
      result += `(${key})`;
    } else {
      result += `&(${key})`;
    }
  });
  return result;
};
