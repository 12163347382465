import React, { useEffect, useState } from "react";
import { BaseProps, KYCDocType, KYCStatus, ToastType } from "../../types";
import { Service } from "../../service";
import { showToast } from "../../util/Toast";
import { IDSecureAPI } from "../../types/api";
import Aadhar from "./Aadhar";
import PAN from "./PAN";
import VoterId from "./VoterId";
import DrivingLicense from "./DrivingLicense";
import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";
import { Link } from "react-router-dom";
import { constructKYCConfigKeyFromConfig } from "../../util";

export default function KYC(props: BaseProps) {
  const [loading, setLoading] = useState(false);
  const [kyc, setKYC] = useState<IDSecureAPI.Response.KYC["result"] | null>(
    null
  );

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        //@ts-ignore
        const kycId = props.match.params["id"];
        //@ts-ignore
        const token = props.match.params["token"];
        const { result } = await Service.getKYCByIdAndToken(kycId, token);

        setKYC(result);
        setLoading(false);
      } catch (error: any) {
        if (error && error.code === "EC-015") {
          setTimeout(() => {
            window.location.href = "https://idsecure.in";
          }, 8000);

          return;
        }
        showToast(
          ToastType.ERROR,
          error.message
            ? error.message
            : "Something went wrong! Please try again!"
        );
        setLoading(false);
        return;
      }
    };

    init();
  }, []);

  function getKYCComponent(
    kyc: IDSecureAPI.Response.KYC["result"]
  ): JSX.Element {
    if (
      constructKYCConfigKeyFromConfig(kyc.config) ===
      "(INDIVIDUAL_PAN)&(AADHAR)"
    ) {
      //TODO FIX
      return <div></div>;
    } else if (kyc && kyc.docs && kyc.docs.length) {
      const component = kyc?.docs.map((doc) => {
        if (doc.type === KYCDocType.AADHAR) {
          return (
            <Aadhar
              doc={doc}
              kycId={kyc.id}
              history={props.history}
              location={props.location}
              match={props.match}
              token={kyc.verificationToken}
            ></Aadhar>
          );
        } else if (doc.type === KYCDocType.INDIVIDUAL_PAN) {
          return (
            <PAN
              history={props.history}
              location={props.location}
              match={props.match}
              token={kyc.verificationToken}
              kycDoc={doc}
              kycDocId={doc.id}
              kycId={kyc.id}
              type={doc.type}
            ></PAN>
          );
        } else if (doc.type === KYCDocType.VOTER_ID) {
          return (
            <VoterId
              history={props.history}
              location={props.location}
              match={props.match}
              token={kyc.verificationToken}
              kycDoc={doc}
              kycDocId={doc.id}
              kycId={kyc.id}
              type={doc.type}
            ></VoterId>
          );
        } else if (doc.type === KYCDocType.DRIVING_LICENSE) {
          return (
            <DrivingLicense
              history={props.history}
              location={props.location}
              match={props.match}
              token={kyc.verificationToken}
              kycDoc={doc}
              kycDocId={doc.id}
              kycId={kyc.id}
              type={doc.type}
            ></DrivingLicense>
          );
        }
      });

      return component && component.length ? (
        <div>{component}</div>
      ) : (
        <div></div>
      );
    } else {
      return <div></div>;
    }
  }

  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div className="container" style={{ marginTop: "6rem" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            {kyc?.status !== KYCStatus.SUCCESSFUL &&
            kyc?.status !== KYCStatus.IN_REVIEW &&
            kyc ? (
              <div>
                <h3>Hey, {kyc?.name}</h3>
                <h6>
                  {kyc?.orgName} is requesting you to verify the following
                  identity for {kyc?.purpose}
                </h6>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {(kyc?.docs && kyc.docs.length < 1) || !kyc || !kyc.docs ? (
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6>
                  {" "}
                  Your KYC has been successfully processed. If you feel there is
                  an issue please contact support. You will be redirected
                  shortly.
                  <br></br>If not redirected please click here{"  "}
                  <a href="https://idsecure.in">IDSecure</a>
                </h6>
              </div>
            </div>
          </div>
        ) : (
          getKYCComponent(kyc)
        )}
      </div>

      <Footer></Footer>
    </React.Fragment>
  );
}
