import { AxiosError, AxiosResponse } from "axios";

import { KYCConfig, KYCDocType, KYCDocVerificationMethod } from "../types";
import { Error, IDSecureAPI } from "../types/api";
import { httpClient } from "./httpClient";

const handleAPIError = (err: any): Error => {
  if (err instanceof AxiosError) {
    if (err && err.response && err.response.data && err.response.data.error) {
      return err.response.data.error as Error;
    }
  }

  return {
    code: "EC-000",
    message: "Something went wrong! Please try again",
  } as Error;
};

export class Service {
  static async updateKYCDoc(
    kycId: string,
    token: string,
    kycDocId: string,
    details: {
      idNumber?: string;
      name?: string;
      dob?: string;
      verificationMethod: KYCDocVerificationMethod;
      idType: KYCDocType;
      consent: boolean;
    }
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.UpdateKYCDoc> =
        await httpClient.unauthorized.patch(
          `/v1/kyc/${kycId}/${token}/doc/${kycDocId}`,
          details
        );

      return result.data;
    } catch (err) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async initiateAadharVerification(
    kycId: string,
    token: string,
    kycDocId: string,
    consent: boolean
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.InitiateAadharVerification> =
        await httpClient.unauthorized.patch(
          `/v1/kyc/${kycId}/${token}/doc/${kycDocId}/aadhar/init`,
          {
            consent,
          }
        );

      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async generateOTPForAadharValidation(
    kycId: string,
    token: string,
    kycDocId: string,
    consent: boolean,
    aadharNumber: string
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.GenerateOTPForAadharVerification> =
        await httpClient.unauthorized.patch(
          `/v1/kyc/${kycId}/${token}/doc/${kycDocId}/aadhar`,
          {
            consent,
            aadharNumber,
          }
        );
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async validateOTPForAadharVerification(
    kycId: string,
    token: string,
    kycDocId: string,
    consent: boolean,
    otp: string
  ) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.ValidateOTPForAadharVerification> =
        await httpClient.unauthorized.patch(
          `/v1/kyc/${kycId}/${token}/doc/${kycDocId}/aadhar/otp`,
          {
            consent,
            otp,
          }
        );
      return result.data;
    } catch (err) {
      const error = handleAPIError(err);
      throw error;
    }
  }

  static async getKYCByIdAndToken(id: string, token: string) {
    try {
      const result: AxiosResponse<IDSecureAPI.Response.KYC> =
        await httpClient.unauthorized.get(`/v1/kyc/${id}/${token}`);
      return result.data;
    } catch (err: any) {
      const error = handleAPIError(err);
      throw error;
    }
  }
}
