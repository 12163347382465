import { title } from "process";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Service } from "../../service";
import {
  BaseProps,
  KYCDoc,
  KYCDocStatus,
  KYCStatus,
  ToastType,
} from "../../types";
import { showToast } from "../../util/Toast";

import "./kyc.css";

export default function Aadhar(
  props: BaseProps & {
    kycId: string;
    token: string;
    doc: KYCDoc;
  }
) {
  const [aadharNumber, setAadharNumber] = useState<string | null>(null);
  // const [captchaImage, setCaptchaImage] = useState<string | null>(null);
  // const [captcha, setCaptcha] = useState<string | null>(null);
  const [consent, setConsent] = useState<boolean>(false);
  const [otp, setOTP] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<
    "GENERATE_OTP" | "VALIDATE_OTP" | "COMPLETED"
  >("GENERATE_OTP");
  const [otpMessage, setOtpMessage] = useState("");

  useEffect(() => {
    const status = props.doc.status;
    if (
      status === KYCDocStatus.IN_REVIEW ||
      status === KYCDocStatus.SUCCESSFUL
    ) {
      window.location.href = "https://idsecure.in";
    }
  }, []);

  const generateOTPForAadharVerification = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!aadharNumber) {
      showToast(ToastType.ERROR, "Aadhar number is empty");
      return;
    }

    if (!consent) {
      showToast(ToastType.ERROR, "Consent required to proceed");
      return;
    }

    if (aadharNumber.length !== 12) {
      showToast(ToastType.ERROR, "Aadhar number should be of 12 digits");
      return;
    }

    try {
      setLoading(true);
      const { kycId, token, doc } = props;

      const { result } = await Service.generateOTPForAadharValidation(
        kycId,
        token,
        doc.id,
        consent,
        aadharNumber
      );

      if (
        result.status !== KYCDocStatus.PROCESSING &&
        result.status !== KYCDocStatus.IN_REVIEW &&
        result.status !== KYCDocStatus.SUCCESSFUL
      ) {
        showToast(
          ToastType.ERROR,
          "Something went wrong from our end! Please refresh your page and try again"
        );
        setLoading(false);
        return;
      }

      setOtpMessage(
        result.last3DigitsOfLinkedMobileNumber
          ? `OTP has been sent to the registered mobile number ending with ${result.last3DigitsOfLinkedMobileNumber}`
          : `OTP has been sent to the registered mobile number!`
      );

      showToast(
        ToastType.SUCCESS,
        "OTP Generated successfully, Please enter the OTP sent to the linked mobile number"
      );
      setStep("VALIDATE_OTP");
      setLoading(false);
    } catch (err: any) {
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
      setLoading(false);
      return;
    }
  };

  const validateOTPForAadharVerification = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!otp) {
      showToast(ToastType.ERROR, "Please enter OTP");
      return;
    }

    setLoading(true);

    try {
      const { kycId, doc, token } = props;

      const { result } = await Service.validateOTPForAadharVerification(
        kycId,
        token,
        doc.id,
        true,
        otp
      );

      if (
        result.status !== KYCDocStatus.IN_REVIEW &&
        result.status !== KYCDocStatus.SUCCESSFUL
      ) {
        showToast(
          ToastType.ERROR,
          result.remarks
            ? result.remarks
            : "Something went wrong! Please try again"
        );
        setLoading(false);
        window.location.reload();
        return;
      }

      showToast(ToastType.SUCCESS, "OTP Verified Successfully");
      setStep("COMPLETED");
      setLoading(false);
    } catch (err: any) {
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
      setLoading(false);
      return;
    }
  };

  // const initiateAadharVerificationComponent: JSX.Element = (
  //   <div className="row d-flex justify-content-center">
  //     <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login">
  //       <h4 className="login-title">Verify Aadhar 💳</h4>
  //       <div className="form-check">
  //         <input
  //           className="form-check-input"
  //           type="checkbox"
  //           checked={consent}
  //           onChange={() => setConsent(!consent)}
  //           id="exampleCheckbox"
  //         />
  //         <label className="form-check-label" htmlFor="exampleCheckbox">
  //           By starting verification process, you agree to give consent to fetch
  //           your data from UIDAI and validate it.
  //         </label>
  //       </div>
  //       <br></br>
  //       <button
  //         className={`btn btn-success btn-block align-self-center btn-login-submit ${
  //           loading ? "disabled" : ""
  //         } ${!consent ? "disabled" : ""}`}
  //         onClick={initiateAadharVerification}
  //         disabled={loading}
  //         style={{ display: step !== "INIT" ? "none" : "flex" }}
  //       >
  //         {loading && (
  //           <span
  //             className="spinner-border spinner-border-sm me-2 mr-2"
  //             role="status"
  //           ></span>
  //         )}
  //         {loading ? "Loading..." : "Start Aadhar Verification"}
  //       </button>
  //       <br></br>
  //     </div>
  //   </div>
  // );

  const generateOTPForAadharValidationComponent: JSX.Element = (
    <div className="row d-flex justify-content-center">
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login">
        <h4 className="login-title">Verify Aadhar 💳</h4>

        <form className="form-group d-flex flex-column">
          <p className="w-75 alert alert-info text-center align-self-center">
            <i className="fa fa-info-circle"></i>
            {"  "}Please do not refresh the page.
          </p>

          <label
            className="w-75 align-self-center"
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
          >
            Aadhar Number
          </label>
          <input
            className="form-control w-75 align-self-center"
            placeholder="Aadhar Card Number"
            type="text"
            name="Aadhar"
            onChange={(e) => setAadharNumber(e.target.value)}
            value={aadharNumber ? aadharNumber : undefined}
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
          />
          <br></br>
          {/* <img
            className="img-fluid form-control w-75 align-self-center"
            src={captchaImage || undefined}
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
            alt="captcha"
          ></img>
          <br></br>

          <label
            className="w-75 align-self-center"
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
          >
            Captcha
          </label> */}
          {/* <input
            className="form-control w-75 align-self-center"
            placeholder="Captcha"
            type="text"
            name="Aadhar"
            onChange={(e) => setCaptcha(e.target.value)}
            value={captcha ? captcha : undefined}
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
          /> */}
          <br></br>

          <div className="form-check align-self-center w-75">
            <input
              className="form-check-input"
              type="checkbox"
              checked={consent}
              onChange={() => setConsent(!consent)}
              id="exampleCheckbox"
            />

            <label className="form-check-label" htmlFor="exampleCheckbox">
              By starting verification process, you agree to give consent to
              fetch your data,store and share it securely.
            </label>
          </div>
          <br></br>

          <button
            className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
              loading ? "disabled" : ""
            }`}
            onClick={generateOTPForAadharVerification}
            disabled={loading}
            style={{ display: step !== "GENERATE_OTP" ? "none" : "flex" }}
          >
            {loading && (
              <span
                className="spinner-border spinner-border-sm me-2 mr-2"
                role="status"
              ></span>
            )}
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>

        <br></br>
      </div>
    </div>
  );

  const validateOTPForAadharVerificationComponent: JSX.Element = (
    <div className="row d-flex justify-content-center">
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login">
        <h4 className="login-title">Verify Aadhar 💳</h4>

        <form className="form-group d-flex flex-column">
          <p className="w-75 alert alert-info text-center align-self-center">
            <i className="fa fa-info-circle"></i>
            {"  "}Please do not refresh the page.
          </p>

          {/* {otpMessage ? (
            <p className="w-75 alert alert-info text-center align-self-center">
              <i className="fa fa-info-circle"></i>
              {otpMessage}
            </p>
          ) : (
            <div></div>
          )} */}

          <input
            className="form-control w-75 align-self-center"
            placeholder="OTP"
            type="text"
            name="OTP"
            onChange={(e) => setOTP(e.target.value)}
            value={otp ? otp : undefined}
            style={{ display: step !== "VALIDATE_OTP" ? "none" : "flex" }}
          />
          <br></br>
          <p className="w-75 text-center align-self-center">
            {"  "}
            {otpMessage}
          </p>

          <button
            className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
              loading ? "disabled" : ""
            }`}
            onClick={validateOTPForAadharVerification}
            disabled={loading}
            style={{ display: step !== "VALIDATE_OTP" ? "none" : "flex" }}
          >
            {loading && (
              <span
                className="spinner-border spinner-border-sm me-2 mr-2"
                role="status"
              ></span>
            )}
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>

        <br></br>
      </div>
    </div>
  );

  const aadharVerificationCompletedComponent: JSX.Element = (
    <div className="row d-flex justify-content-center">
      <div className="col-xl-6 col-lg-6 col-sm-12 login">
        <div
          className="alert alert-info"
          style={{ padding: "1rem", marginTop: "1rem" }}
        >
          🎉 Congratulations! Your KYC process has been successfully completed.{" "}
        </div>
      </div>
    </div>
  );

  const getAadharVerificationComponentBasedOnStep = (): JSX.Element => {
    if (step === "GENERATE_OTP") {
      return generateOTPForAadharValidationComponent;
    } else if (step === "VALIDATE_OTP") {
      return validateOTPForAadharVerificationComponent;
    } else if (step === "COMPLETED") {
      return aadharVerificationCompletedComponent;
    }

    return aadharVerificationCompletedComponent;
  };

  return (
    <React.Fragment>
      <div className="container">
        {getAadharVerificationComponentBasedOnStep()}
      </div>
    </React.Fragment>
  );
}
