import React from "react";

import { RouteComponentProps } from "react-router-dom";

export enum ToastType {
  "SUCCESS" = "SUCCESS",
  "ERROR" = "ERROR",
}

export enum KYCStatus {
  CREATED = "CREATED",
  DRAFT = "DRAFT",
  IN_REVIEW = "IN_REVIEW",
  SUCCESSFUL = "SUCCESSFUL",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
}

export enum KYCDocStatus {
  CREATED = "CREATED",
  IN_REVIEW = "IN_REVIEW",
  PROCESSING = "PROCESSING",
  SUCCESSFUL = "SUCCESSFUL",
  REJECTED = "REJECTED",
  CANCELLED = "CANCELLED",
  NO_ACTION_REQUIRED = "NO_ACTION_REQUIRED",
  FAILED = "FAILED",
}

export enum KYCDocType {
  CERTIFICATE_OF_INCORPORATION = "CERTIFICATE_OF_INCORPORATION",
  COMPANY_PAN_CARD = "COMPANY_PAN_CARD",
  COMPANY_BANK_STATEMENT = "COMPANY_BANK_STATEMENT",
  GST_CERTIFICATE = "GST_CERTIFICATE",
  TRUST_DEED = "TRUST_DEED",
  HUF_DEED = "HUF_DEED",
  AADHAR = "AADHAR",
  INDIVIDUAL_PAN = "INDIVIDUAL_PAN",
  VOTER_ID = "VOTER_ID",
  DRIVING_LICENSE = "DRIVING_LICENSE",
  PARTNERSHIP_DEED = "PARTNERSHIP_DEED",
  TIN_REGISTRATION = "TIN_REGISTRATION",
  VAT_REGISTRATION = "VAT_REGISTRATION",
  TRADE_LICENSE = "TRADE_LICENSE",
  CANCELLED_CHEQUE = "CANCELLED_CHEQUE",
}

export enum KYCDocVerificationMethod {
  ID_NUMBER_API = "ID_NUMBER_API",
  MANUAL_FILE = "MANUAL_FILE",
  BOTH = "BOTH",
}

export interface KYCConfig {
  name: string;
  description?: string;
  //AND
  documents: {
    [key in string]: {
      // OR
      document: KYCDocType;
      verificationMethod: KYCDocVerificationMethod;
    }[];
  };
}

export interface KYCDoc {
  id: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  kycId: string | null;
  orgKYCId: string;
  groupId: string;
  status: KYCDocStatus;
  type: KYCDocType;
  idNumber: string | null;
  nameOnId: string | null;
  dob: string | null;
  file: string | null;
  fileUploadedAt: string | null;
  verificationMethod: KYCDocVerificationMethod;
  isExternalVerification: boolean;
  externalVerificationPartner: string | null;
  externalVerificationLink: string | null;
  externalVerificationRefNumber: string | null;
  externalData: object | null;
  verifiedAt: string | null;
  verifiedBy: string | null;
  remarks: string | null;
  purpose: string | null;
  consent: string | null;
  failureCode: string | null;
  metadata: object | null;
}

export interface BaseProps extends React.PropsWithChildren {
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
}
