import axios, { AxiosInstance } from "axios";

class HttpClient {
  private _unauthorized: AxiosInstance | null = null;

  initializeUnAuthorizedClient() {
    this._unauthorized = axios.create({
      baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  public get unauthorized(): AxiosInstance {
    if (!this._unauthorized) {
      throw new Error(
        "Client not initialized, Please contact support with error code: EC-IN-0001"
      );
    }
    return this._unauthorized;
  }
  public set unauthorized(value: AxiosInstance | null) {
    this._unauthorized = value;
  }
}

const httpClient = new HttpClient();
httpClient.initializeUnAuthorizedClient();

export { httpClient };
