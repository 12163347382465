import Navbar from "../../components/layout/Navbar";
import { Service } from "../../service";
import {
  BaseProps,
  KYCDocType,
  KYCDoc,
  KYCDocStatus,
  ToastType,
} from "../../types";
import { showToast } from "../../util/Toast";
import "./kyc.css";

import { SyntheticEvent, useEffect, useState } from "react";

export default function VoterId(
  props: BaseProps & {
    type: KYCDocType.VOTER_ID;
    kycId: string;
    kycDocId: string;
    kycDoc: KYCDoc;
    token: string;
  }
) {
  const [loading, setLoading] = useState(false);
  const [voterId, setVoterId] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [status, setStatus] = useState<KYCDocStatus>(KYCDocStatus.CREATED);

  const [consent, setConsent] = useState(false);

  const handleVoterIDSubmission = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!voterId || voterId === "") {
        setLoading(false);
        showToast(ToastType.ERROR, "Invalid Voter ID Number");
        return;
      }

      if (voterId.length < 10) {
        setLoading(false);
        showToast(ToastType.ERROR, "Invalid Voter ID Number");
        return;
      }

      if (!consent) {
        setLoading(false);
        showToast(
          ToastType.ERROR,
          "Please provide consent to validate ID details"
        );
        return;
      }

      if (!name) {
        setLoading(false);
        showToast(ToastType.ERROR, "Please enter your name");
        return;
      }

      const { result } = await Service.updateKYCDoc(
        props.kycId,
        props.token,
        props.kycDocId,
        {
          idNumber: voterId,
          consent,
          idType: props.kycDoc.type,
          verificationMethod: props.kycDoc.verificationMethod,
          name,
        }
      );
      setStatus(result.status);
      showToast(ToastType.SUCCESS, "Voter ID Verified successfully");
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      showToast(
        ToastType.ERROR,
        err.message ? err.message : "Something went wrong! Please try again!"
      );
    }
  };

  function getVoterIdComponent(): JSX.Element {
    switch (status) {
      case KYCDocStatus.CREATED: {
        return (
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 login">
            <h4 className="login-title">Verify Voter ID 💳</h4>

            <form className="form-group d-flex flex-column">
              <label className="w-75 align-self-center">Voter ID Number</label>
              <input
                className="form-control w-75 align-self-center"
                placeholder="Voter ID Number"
                type="text"
                name="voterId"
                onChange={(e) => setVoterId(e.target.value)}
                value={voterId ? voterId : undefined}
              />
              <br></br>
              <label className="w-75 align-self-center">Name</label>
              <input
                className="form-control w-75 align-self-center"
                placeholder="Name"
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name ? name : undefined}
              />
              <br></br>

              <div className="form-check form-check w-75 align-self-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={consent}
                  onChange={() => setConsent(!consent)}
                  id="exampleCheckbox"
                />
                <label className="form-check-label" htmlFor="exampleCheckbox">
                  By starting verification process, you agree to give consent to
                  fetch your data and validate it.
                </label>
              </div>
              <br></br>
              <button
                className={`w-75 btn btn-success btn-block align-self-center btn-login-submit ${
                  loading ? "disabled" : ""
                }`}
                onClick={handleVoterIDSubmission}
                disabled={loading}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm me-2 mr-2"
                    role="status"
                  ></span>
                )}
                {loading ? "Loading..." : "Submit"}
              </button>
            </form>

            <br></br>
          </div>
        );
      }
      case KYCDocStatus.PROCESSING:
      case KYCDocStatus.IN_REVIEW:
      case KYCDocStatus.SUCCESSFUL: {
        return (
          <div className="row d-flex justify-content-center">
            <div className="col-xl-9 col-lg-9 col-sm-12 login">
              <div
                className="alert alert-info"
                style={{ padding: "1rem", marginTop: "1rem" }}
              >
                🎉 Congratulations! Your KYC process has been successfully
                completed.{" "}
              </div>
            </div>
          </div>
        );
      }
      case KYCDocStatus.REJECTED:
      case KYCDocStatus.CANCELLED: {
        return (
          <div className="row d-flex justify-content-center">
            <div className="col-xl-9 col-lg-9 col-sm-12 login">
              <div
                className="alert alert-info"
                style={{ padding: "1rem", marginTop: "1rem" }}
              >
                Your KYC process is completed.No Actions required from
                you.Please contact support if you have any issues.{" "}
              </div>
            </div>
          </div>
        );
      }
      default: {
        return <div></div>;
      }
    }
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <br></br>
        <div className="row d-flex justify-content-center">
          {getVoterIdComponent()}
        </div>
      </div>
    </div>
  );
}
